<template>
  <Modal
    v-model="showModal"
    title="Disponibilité des chambres"
    modalClass="fullWidthModal"
    @close="modalClosed"
  >
    <div class="row">
      <div class="col-md-3">
        <VueCtkDateTimePicker
          locale="fr"
          v-model="date"
          style="display:inline-block;margin-bottom: 20px;"
          :range="true"
          color="#34495e"
          :no-label="true"
          :custom-shortcuts="customDateRangeShortcuts"
          :only-date="true"
          :auto-close="false"
        ></VueCtkDateTimePicker>
      </div>
      <div class="col-md-3">
        <multiselect
          :searchable="true"
          v-model="site"
          track-by="id"
          @input="loadBlocs"
          label="name"
          :options="camps"
          placeholder="Site"
          :allow-empty="false"
        >
          <template slot="singleType" slot-scope="{ camp }">{{
            camp.name
          }}</template>
        </multiselect>
      </div>
      <div class="col-md-3">
        <multiselect
          :searchable="true"
          v-model="bloc"
          track-by="id"
          label="name"
          :options="blocs"
          placeholder="Bloc"
          :allow-empty="false"
        >
          <template slot="singleType" slot-scope="{ bloc }">{{
            bloc.name
          }}</template>
        </multiselect>
      </div>

      <div class="col-md-2">
        <button class="btn btn-primary" @click="searchAvailableRooms">
          <i class="bx bx-search-alt-2"></i> Rechercher
        </button>
      </div>
      <div class="col-md-8 colorsHelp">
        Aide:
        <span class="badge badge-pill bg-success">Chambre Réservée</span>
        <span class="badge badge-pill bg-primary">Chambre Affectée</span>
        <span class="badge badge-pill bg-danger">Chambre Occupée</span>
        <span class="badge badge-pill bg-warning">Chambre En maintenance</span>
        <span class="badge badge-pill bg-secondary">Chambre Hors service</span>
      </div>
      <br />
    </div>
    <br />

    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-striped table-bordered mb-0 room_status_table">
            <thead>
              <tr>
                <th class="">
                  <span class="">Chambre N°:</span>
                </th>
                <th v-for="data in x_axis" v-bind:key="data">{{ data }}</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr>
                <th
                  colspan="16"
                  style="text-align: center;background: #dfe6e9;"
                >
                  Chambre VIP
                </th>
              </tr> -->
              <tr
                class="full-width"
                style="border-bottom: 1px solid black;"
                v-for="(room, index) in y_axis"
                v-bind:key="index"
              >
                <th >{{ room.room }}</th>
                <td
                  style="border: 1px solid black !important;"
                  v-for="(stat, index) in room.stats"
                  v-bind:key="index"
                  :colspan="stat.cols"
                  :class="`room_${stat.class}`"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    from: {},
    to: {},
    booking: {},
    user: {},
    roomType: {},
    camps: {},
  },
  watch: {
    show() {
      this.showModal = this.show;
    },

    showModal() {
      // call api to get data
      if (this.showModal) {
        // this.searchAvailableRooms();
      }
    },
  },
  created() {
    // this.date.start = this.getNow();
  },
  data() {
    return {
      sites: [],
      blocs: [],
      site: "",
      bloc :"",
      showModal: this.show,
      date: {},
      x_axis: [],
      y_axis: [],
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
    };
  },

  methods: {
    loadBlocs(){
      this.blocs= this.site.buildings
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    searchAvailableRooms() {
      var from = this.date.start ? this.date.start : this.from;
      var to = this.date.start ? this.date.end : this.to;
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/searchRoomsAvailabilityBeta", {
          from: from,
          to: to,
          roomType: this.roomType,
          site : this.site,
          bloc : this.bloc
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var response = res.data.availableRooms;
          switch (status) {
            case 200:
              // this.$toast.success(res.data.original.msg);
              // console.log(response);
              this.x_axis = response.x_axis;
              this.y_axis = response.y_axis;
              break;

            case 500:
              this.$toast.warning(res.data.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    modalClosed() {
      this.$emit("closeAvailabilityModal", false);
    },
  },
};
</script>
