<template>
  <fieldset>
    <div class="card">
      <div class="card-body">
        <b-tabs content-class="p-3 text-muted">
          <b-tab active class="border-0">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Informations</span>
            </template>
            <div class="row">
              <div class="col-lg-12">
                <b-alert show variant="info" v-if="bookingForm.ref">
                  <i class="mdi mdi-alert-circle-outline mr-2"></i>Détails de
                  réservation N°: <b>{{ bookingForm.ref }}</b>
                </b-alert>
                <div
                  class="row singleBookingActions"
                  v-if="
                    bookingForm.ref &&
                      (bookingForm.statusPlain == 'approved' ||
                        bookingForm.statusPlain == 'checkin')
                  "
                >
                  <button
                    class="btn btn-danger"
                    v-if="$can('cancel_booking')"
                    @click.prevent="handleBookingCancelClick"
                  >
                    <i class="fas fa-close"></i> Annuler
                  </button>
                  <button
                    v-if="showCheckinButton"
                    class="btn btn-primary"
                    @click.prevent="showCheckinModal"
                  >
                    <i class="fas fa-check"></i> Check In
                  </button>
                  <button
                    v-if="showCheckoutButton"
                    class="btn btn-warning"
                    @click.prevent="showCheckOutModal"
                  >
                    <i class="fas fa-window-close"></i> Check Out
                  </button>
                  <button
                    class="btn btn-info"
                    @click.prevent="events.showProlongationModal=true"
                  >
                    <i class="far fa-calendar-plus"></i> Ajouter Prolongement
                  </button>
                  <button
                    class="btn btn-secondary"
                    @click.prevent="events.showRelocationModal = true"
                  >
                    <i class="mdi mdi-repeat"></i> Ajouter Délogement
                  </button>
                </div>

                <div
                  class="row singleBookingActions"
                  v-if="bookingForm.ref && bookingForm.statusPlain == 'draft'"
                >
                  <button
                    class="btn btn-primary"
                    v-b-modal.approvalModal
                    v-if="$can('approve_booking')"
                    @click.prevent="showApprobationModal"
                  >
                    <i class="fas fa-check"></i> Approuver
                  </button>
                  <button
                    class="btn btn-danger"
                    v-b-modal.rejectionModal
                    v-if="$can('reject_booking')"
                    @click.prevent="showRejectionModal"
                  >
                    <i class="fas fa-window-close"></i> Rejeter
                  </button>
                </div>
                <fieldset :disabled="formDisabled">
                  <form
                    disabled
                    class="needs-validation"
                    @submit.prevent="formSubmit"
                  >
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity"
                            >Type de bénéficiaire *</label
                          >
                          <multiselect
                            v-model="bookingForm.beneficiaryType"
                            :searchable="true"
                            track-by="id"
                            open-direction="bottom"
                            label="name"
                            :options="beneficiaryTypes"
                            @input="onBeneficiaryTypeChange"
                            placeholder=""
                            :allow-empty="false"
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.bookingForm.beneficiaryType.$error,
                              isDisabledDropdown: disableImportantFields || disableBenefType,
                            }"
                          >
                            <template slot="singleRoom" slot-scope="{ type }">{{
                              type.name
                            }}</template>
                          </multiselect>
                          <div
                            v-if="
                              submitted && $v.bookingForm.beneficiaryType.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="!$v.bookingForm.beneficiaryType.required"
                              >Champs Obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3" v-if="bookingForm.beneficiaryType.id == 'sh_employee'">
                        <div class="form-group">
                          <label for="formrow-inputCity"
                            >Structure *</label
                          >
                          <multiselect
                            v-model="bookingForm.structId"
                            v-if="bookingForm.structName == ''"
                            :searchable="true"
                            track-by="code"
                            open-direction="bottom"
                            label="name"
                            :options="structures"
                            @input="onStructChange"
                            placeholder=""
                            :allow-empty="false"
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.bookingForm.beneficiaryType.$error,
                              isDisabledDropdown: disableImportantFields,
                            }"
                          >
                            <template slot="singleRoom" slot-scope="{ type }">{{
                              type.name
                            }}</template>
                          </multiselect>
                          <input type="text" class="form-control" v-else disabled :value="bookingForm.structName">
                          <div
                            v-if="
                              submitted && $v.bookingForm.beneficiaryType.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="!$v.bookingForm.beneficiaryType.required"
                              >Champs Obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3" v-if="bookingForm.beneficiaryType.id == 'visitor'">
                        <div class="form-group">
                          <label for="formrow-inputCity"
                            >Entreprise *</label
                          >
                          <multiselect
                            v-model="bookingForm.companyId"
                            v-if="!bookingForm.companyName"
                            :searchable="true"
                            track-by="id"
                            open-direction="bottom"
                            label="company_name"
                            :options="companies"
                            @input="onCompanyChange"
                            placeholder=""
                            :allow-empty="false"
                          >
                            <template slot="singleRoom" slot-scope="{ type }">{{
                              type.company_name
                            }}</template>
                          </multiselect>
                          <input v-else type="text" class="form-control" disabled :value="bookingForm.companyName">
                          
                        </div>
                      </div>

                      <div class="col-lg-3" v-if="bookingForm.beneficiaryType.id == 'supplier_employee'">
                        <div class="form-group">
                          <label for="formrow-inputCity"
                            >Prestataire *</label
                          >
                          <multiselect
                            v-model="bookingForm.supplierId"
                            v-if="!bookingForm.companyName"
                            :searchable="true"
                            track-by="id"
                            open-direction="bottom"
                            label="name"
                            :options="suppliers"
                            @input="onSupplierChange"
                            placeholder=""
                            :allow-empty="false"
                          >
                            <template slot="singleRoom" slot-scope="{ supplier }">{{
                              supplier.name
                            }}</template>
                          </multiselect>
                          <input v-else type="text" class="form-control" disabled :value="bookingForm.companyName">
                          
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Bénéficiaire *</label>
                          
                          <multiselect
                            v-model="bookingForm.beneficiaryId"
                            v-if="!events.showEmployeeNameField && (bookingForm.beneficiaryType.id == 'sh_employee' || bookingForm.beneficiaryType.id == 'supplier_employee')"
                            :searchable="true"
                            track-by="id"
                            label="empnum_fname"
                            :options="beneficiaries"
                            placeholder=""
                            :class="{
                              isDisabledDropdown:
                                bookingForm.beneficiaryType == '' ||
                                disableImportantFields,
                              'is-invalid':
                                submitted &&
                                $v.bookingForm.beneficiaryId.$error,
                            }"
                            :allow-empty="false"
                          >
                            <template
                              slot="singleRoom"
                              slot-scope="{ benef }"
                              >{{ benef.empnum_fname }}</template
                            >
                          </multiselect>
                          <span v-if="bookingForm.beneficiaryType.id == 'sh_employee'"><b>{{ bookingForm.beneficiaryId.post + '/' }} Chambre affectée: {{ bookingForm.beneficiaryId.room_no ? bookingForm.beneficiaryId.room_no : 'N/A' }}</b></span>
                          <input v-if="events.showEmployeeNameField" :value="bookingForm.beneficiaryFullName" disabled type="text" class="form-control">


                          <multiselect
                            v-model="bookingForm.beneficiaryId"
                            v-if="!events.showVisitorNameField && bookingForm.beneficiaryType.id == 'visitor'"
                            :searchable="true"
                            track-by="id"
                            label="full_name"
                            :options="beneficiaries"
                            placeholder=""
                            :class="{
                              isDisabledDropdown:
                                bookingForm.beneficiaryType == '' ||
                                disableImportantFields
                            }"
                            :allow-empty="false"
                          >
                            <template
                              slot="singleRoom"
                              slot-scope="{ benef }"
                              >{{ benef.full_name }}</template
                            >
                          </multiselect>
                          

                          <input v-if="events.showVisitorNameField" :value="bookingForm.visitorName" disabled type="text" class="form-control">
                          <div
                            v-if="
                              submitted && $v.bookingForm.beneficiaryId.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.bookingForm.beneficiaryId.required"
                              >Champs Obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Site *</label>

                          <multiselect
                            v-model="bookingForm.campId"
                            :searchable="true"
                            track-by="id"
                            label="name"
                            :options="camps"
                            placeholder="Selectionner le site"
                            :class="{
                              isDisabledDropdown: disableImportantFields,
                            }"
                            :allow-empty="false"
                            @input="onCampChange"
                          >
                            <template slot="singleCamp" slot-scope="{ camp }">{{
                              camp.name
                            }}</template>
                          </multiselect>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Bloc *</label>
                          <multiselect
                            v-model="bookingForm.bloc"
                            :searchable="true"
                            track-by="id"
                            label="name"
                            :options="blocs"
                            placeholder="Sélectionnez un Bloc"
                            :allow-empty="false"
                          >
                            <template slot="singleBloc" slot-scope="{ bloc }">
                              {{ bloc.name }}
                            </template>
                          </multiselect>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity"
                            >Type de chambre *</label
                          >

                          <multiselect
                            v-model="bookingForm.roomType"
                            :searchable="true"
                            track-by="id"
                            label="designation"
                            :options="roomsTypes"
                            placeholder="Select room type"
                            :allow-empty="false"
                          >
                            <template slot="singleType" slot-scope="{ type }">{{
                              type.designation
                            }}</template>
                          </multiselect>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formrow-password-input">Arrivé *</label>
                          <input
                            type="date"
                            :class="{
                              'is-invalid':
                                submitted && $v.bookingForm.dateIn.$error,
                            }"
                            v-model="bookingForm.dateIn"
                            class="form-control"
                          />
                          <div
                            v-if="submitted && $v.bookingForm.dateIn.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.bookingForm.dateIn.required"
                              >Date d'arrivé est obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Départ *</label>
                          <input
                            type="date"
                            :class="{
                              'is-invalid':
                                submitted && $v.bookingForm.dateOut.$error,
                            }"
                            v-model="bookingForm.dateOut"
                            class="form-control"
                          />
                          <div
                            v-if="submitted && $v.bookingForm.dateOut.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.bookingForm.dateOut.required"
                              >Date de Départ est obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Chambre *</label>
                          <multiselect
                            v-model="bookingForm.room"
                            :searchable="true"
                            track-by="id"
                            label="roomName"
                            :options="rooms"
                            placeholder="Sélectionnez une chambre"
                            :allow-empty="false"
                          >
                            <template slot="singleRoom" slot-scope="{ room }">
                              {{ room.roomName }}
                            </template>
                          </multiselect>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Nuités</label>
                          <input
                            type="text"
                            v-model="bookingForm.nights"
                            readonly
                            class="form-control"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="formrow-inputCity">Commentaires *</label>
                          <textarea
                            v-model="bookingForm.comments"
                            class="form-control"
                            cols="30"
                            rows="4"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <button
                          class="btn btn-primary float-right"
                          type="submit"
                        >
                          <i class="fas fa-save"></i>
                          Enregistrer
                        </button>
                        <b-button
                          @click="cancelForm"
                          variant="danger"
                          class="btn btn-light btn-label  mr-2 float-right"
                        >
                          <i
                            class="fas fa-times-circle text-white label-icon"
                          ></i>
                          Annuler
                        </b-button>
                      </div>
                    </div>
                  </form>
                </fieldset>
              </div>

              <!-- New benef Modal -->
              <Modal
                v-model="events.showNewBeneficiaryModal"
                :title="`Ajouter un ${bookingForm.beneficiaryType.name}`"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <b-form>
                      <div class="row">
                        <div class="col-md-6">
                          <b-form-group
                            label="Nom"
                            label-for="formrow-firstname-input"
                          >
                            <b-form-input
                              id="formrow-firstname-input"
                              type="text"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            label="Prénom"
                            label-for="formrow-firstname-input"
                          >
                            <b-form-input
                              id="formrow-firstname-input"
                              type="text"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <b-form-group
                            label="Email"
                            label-for="formrow-firstname-input"
                          >
                            <b-form-input
                              id="formrow-firstname-input"
                              type="text"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            label="Téléphone"
                            label-for="formrow-firstname-input"
                          >
                            <b-form-input
                              id="formrow-firstname-input"
                              type="text"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="bookingForm.beneficiaryType.id != 'visitor'"
                      >
                        <div class="col-md-6">
                          <b-form-group
                            label="N° de badge"
                            label-for="formrow-firstname-input"
                          >
                            <b-form-input
                              id="formrow-firstname-input"
                              type="text"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="bookingForm.beneficiaryType.id == 'visitor'"
                      >
                        <div class="col-md-6">
                          <label for="formrow-inputCity">Entreprise *</label>
                          <multiselect
                            v-model="bookingForm.beneficiaryType"
                            :searchable="true"
                            track-by="id"
                            open-direction="bottom"
                            label="name"
                            :options="beneficiaryTypes"
                            @input="onBeneficiaryTypeChange"
                            placeholder=""
                            :allow-empty="false"
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.bookingForm.beneficiaryType.$error,
                            }"
                          >
                            <template slot="singleRoom" slot-scope="{ type }">{{
                              type.name
                            }}</template>
                          </multiselect>
                        </div>
                        <div class="col-lg-3">
                          <label for="resume">Pièce d'identité</label>
                          <multiselect
                            v-model="newBeneficiaryModal.identityDocType"
                            :searchable="true"
                            track-by="id"
                            label="name"
                            :options="identityDocTypes"
                            placeholder="Selectionner"
                            :allow-empty="false"
                          >
                            <template
                              slot="singleMealType"
                              slot-scope="{ type }"
                              >{{ type.name }}</template
                            >
                          </multiselect>
                        </div>

                        <div class="col-lg-3">
                          <label for="subject">N° Identité</label>
                          <input
                            id="subject"
                            v-model="newBeneficiaryModal.identityDocNo"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <b-button
                            variant="primary"
                            @click="saveNewBenefiaryModal"
                          >
                            <i class="fas fa-save"></i> Enregistrer</b-button
                          >
                        </div>
                      </div>
                    </b-form>
                  </div>
                </div>
              </Modal>

              <!-- RELOCATION MODAL -->
              <Modal
                v-model="events.showRelocationModal"
                :title="`Délogement:  ${bookingForm.beneficiaryId.fullNameUc}`"
              >
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Occupant</label>
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      :value="bookingForm.beneficiaryId.fullNameUc"
                    />
                  </div>

                  <div class="col-md-4">
                    <label for="">Chambre Actuelle</label>
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      :value="
                        'Bloc: ' +
                          bookingForm.room.blocName +
                          '- N° ' +
                          bookingForm.room.number
                      "
                    />
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="formrow-inputCity">Site *</label>

                      <multiselect
                        v-model="relocationModal.campId"
                        :searchable="true"
                        track-by="id"
                        label="name"
                        :options="camps"
                        placeholder="Selectionner le site"
                        :allow-empty="false"
                        @select="relocationModal.blocs = $event.buildings"
                      >
                        <!-- @input="onCampChange" -->
                        <template slot="singleCamp" slot-scope="{ camp }">{{
                          camp.name
                        }}</template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-md-4 mt-1">
                    <label for="">Bloc / Secteur</label>
                    <multiselect
                      v-model="relocationModal.blocId"
                      :searchable="true"
                      track-by="id"
                      open-direction="bottom"
                      label="name"
                      :options="relocationModal.blocs"
                      placeholder=""
                      :allow-empty="false"
                      @input="onRelocationCampChange"
                    >
                      <!-- <template slot="singleRoom" slot-scope="{ room }">{{
                        room.roomName
                      }}</template> -->
                    </multiselect>
                  </div>
                  <div class="col-md-4 mt-1">
                    <label for="">Nouvelle chambre</label>
                    <multiselect
                      v-model="relocationModal.newRoomId"
                      :searchable="true"
                      track-by="id"
                      open-direction="bottom"
                      label="number"
                      :options="relocationModal.alternativeRooms"
                      placeholder=""
                      :allow-empty="false"
                    >
                      <template slot="singleRoom" slot-scope="{ room }">{{
                        room.roomName
                      }}</template>
                    </multiselect>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label for="">Motif</label>
                    <textarea
                      v-model="relocationModal.comments"
                      class="form-control"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>

                  <div class="col-md-12 mt-2">
                    <button
                      class="btn btn-primary float-right"
                      @click="saveRelocation"
                    >
                      <i class="fas fa-save"></i> Enregistrer
                    </button>
                  </div>
                </div>
              </Modal>
              <!-- PROLONGATION MODAL -->
              <Modal
                v-model="events.showProlongationModal"
                :title="`Prolongation:  ${bookingForm.beneficiaryId.fullNameUc}`"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label for="">Occupant</label>
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      :value="bookingForm.beneficiaryId.fullNameUc"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="">Chambre Actuelle</label>
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      :value="
                        'Bloc: ' +
                          bookingForm.room.blocName +
                          '- N° ' +
                          bookingForm.room.number
                      "
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <label for="">Prolonger Du:</label>
                    <input type="date" class="form-control" v-model="prolongationModal.from">
                  </div>
                  <div class="col">
                    <label for="">Prolonger Au:</label>
                    <input type="date" class="form-control" v-model="prolongationModal.to">
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label for="">Commentaires</label>
                    <textarea
                      v-model="prolongationModal.comments"
                      class="form-control"
                      cols="30"
                      rows="2"
                    ></textarea>
                  </div>

                  <div class="col-md-12 mt-2">
                    <button
                      class="btn btn-primary float-right"
                      @click="saveProlongation"
                    >
                      <i class="fas fa-save"></i> Enregistrer
                    </button>
                  </div>
                </div>
              </Modal>
              <!-- CHECKIN MODAL -->
              <Modal
                v-model="events.showCheckInModal"
                modalClass="checkInModal fullWidthModal"
                title="EEEE"
              >
                <template v-slot:titlebar>
                  <div class="vm-titlebar">
                    <h3 class="vm-title">
                      <img
                        :src="
                          require('@/assets/images/base/ops/hosting/check_in.png')
                        "
                        alt=""
                      />
                      <p>
                        Check In Pour la réservation N°:
                        <b>{{ bookingForm.ref }}</b>
                      </p>
                    </h3>
                  </div>
                </template>
                <div class="">
                  <div class="row bookingDataContainer">
                    <div class="col-md-2">
                      <label for="">Référence</label>
                      <p>
                        <b>{{ bookingForm.ref }}</b>
                      </p>
                    </div>
                    <div class="col-md-2">
                      <label for="">Date d'arrivé</label>
                      <p>
                        <b>{{ bookingForm.dateIn }}</b>
                      </p>
                    </div>
                    <div class="col-md-2">
                      <label for="">Date de départ</label>
                      <p>
                        <b>{{ bookingForm.dateOut }}</b> ({{
                          bookingForm.nights
                        }}
                        Nuitées)
                      </p>
                    </div>

                    <div class="col-md-2">
                      <label for="">Occupant</label>
                      <p>
                        <b>{{ bookingForm.beneficiaryId.fullNameUc }}</b>
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label for="">Chambre Affectée</label> <br />
                      <multiselect
                        v-model="checkInModal.roomId"
                        :searchable="true"
                        track-by="id"
                        open-direction="bottom"
                        label="roomName"
                        :options="rooms"
                        placeholder=""
                        :allow-empty="false"
                        :class="{
                          'is-invalid':
                            submitted && $v.checkInModal.roomId.$error,
                        }"
                      >
                        <template slot="singleRoom" slot-scope="{ room }">{{
                          room.roomName
                        }}</template>
                      </multiselect>
                      <div
                        v-if="submitted && $v.checkInModal.roomId.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.checkInModal.roomId.required"
                          >Champ Obligatoire.</span
                        >
                      </div>
                    </div>

                    <div class="col-md-2">
                      <label for="">Opération effectuée par:</label> <br />
                      <a href="javascript:void(0)"
                        >{{ user.first_name }} {{ user.last_name }}</a
                      >
                    </div>

                    <div class="col-md-2">
                      <label for="">Date / heure d'opération:</label> <br />
                      <p>
                        <b>{{ now() }}</b>
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <label for="subject">Commentaires</label>
                      <textarea
                        class="form-control"
                        v-model="checkInModal.comments"
                        cols="30"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                  <br />
                  <div>
                    <button class="btn btn-primary" @click="performCheckin">
                      <i class="fas fa-save"></i>
                      Enregistrer
                    </button>
                  </div>
                </div>
              </Modal>

              <!-- Check Out Modal -->
              <Modal
                v-model="events.showCheckOutModal"
                modalClass="checkInModal fullWidthModal"
              >
                <template v-slot:titlebar>
                  <div class="vm-titlebar">
                    <h3 class="vm-title">
                      <img
                        :src="
                          require('@/assets/images/base/ops/hosting/check_out.png')
                        "
                        alt=""
                      />
                      <p>
                        Check Out Pour la réservation N°:
                        <b>{{ bookingForm.ref }}</b>
                      </p>
                    </h3>
                  </div>
                </template>
                <div class="">
                  <div class="row bookingDataContainer">
                    <div class="col-md-2">
                      <label for="">Référence</label>
                      <p>
                        <b>{{ bookingForm.ref }}</b>
                      </p>
                    </div>
                    <div class="col-md-2">
                      <label for="">Date d'arrivé</label>
                      <p>
                        <b>{{ bookingForm.dateIn }}</b>
                      </p>
                    </div>
                    <div class="col-md-2">
                      <label for="">Date de départ</label>
                      <p>
                        <b>{{ bookingForm.dateOut }}</b> ({{
                          bookingForm.nights
                        }}
                        Nuitées)
                      </p>
                    </div>

                    <div class="col-md-2">
                      <label for="">Occupant</label>
                      <p>
                        <b>{{ bookingForm.beneficiaryId.fullNameUc }}</b>
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label for="">Chambre Affectée</label> <br />
                      <p>
                        Chambre N°: <b>{{ bookingForm.room.number }}</b>
                      </p>
                    </div>

                    <div class="col-md-2">
                      <label for="">Opération effectuée par:</label> <br />
                      <a href="javascript:void(0)"
                        >{{ user.first_name }} {{ user.last_name }}</a
                      >
                    </div>

                    <div class="col-md-2">
                      <label for="">Date / heure d'opération:</label> <br />
                      <p>
                        <b>{{ now() }}</b>
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <label for="subject">Clé remis</label> <br />
                      <b-form-checkbox
                        v-model="checkOutModal.keyReturned"
                        style="display:inline-block;"
                        switch
                        class="mb-1"
                      />
                    </div>
                    <div class="col-md-12">
                      <label for="subject">Commentaires</label>
                      <textarea
                        class="form-control"
                        v-model="checkOutModal.comments"
                        cols="30"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                  <br />
                  <div>
                    <button class="btn btn-primary" @click="performCheckout">
                      <i class="fas fa-save"></i>
                      Enregistrer
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
            <approval-modal
              :modelRef="bookingForm.ref"
              :modelId="bookingForm.id"
              :modelType="bookingForm.modelType"
            />
            <rejection-modal
              :modelRef="bookingForm.ref"
              :modelId="bookingForm.id"
              :modelType="bookingForm.modelType"
            />
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Check-IN</span>
            </template>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Date:</th>
                        <td>{{ bookingForm.checkin_at }}</td>
                      </tr>
                      <tr>
                        <th>Opération par::</th>
                        <td>{{ bookingForm.checkedInBy }}</td>
                      </tr>
                      <tr>
                        <th>Commentaires:</th>
                        <td>
                          {{ bookingForm.checkInComments }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-envelope"></i>
              </span>
              <span class="d-none d-sm-inline-block">Check-OUT</span>
            </template>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Date:</th>
                        <td>{{ bookingForm.checkout_at }}</td>
                      </tr>
                      <tr>
                        <th>Opération par::</th>
                        <td>{{ bookingForm.checkedOutBy }}</td>
                      </tr>
                      <tr>
                        <th>Commentaires:</th>
                        <td>
                          {{ bookingForm.checkOutComments }}
                        </td>
                      </tr>

                      <tr>
                        <th>Remise de clé:</th>
                        <td>
                          <span v-html="bookingForm.keyStatus"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Délogement <span v-if="singleBookingData" class="badge badge-info"> {{singleBookingData.relocations.length}} </span> </span>
            </template>
            <div class="row" v-if="singleBookingData">
              <div class="col-md-12">
                <button
                  class="btn btn-primary"
                  @click="showRelocationModalClick"
                  v-if="$can('create_relocation') && bookingForm.ref && (bookingForm.statusPlain == 'approved' || bookingForm.statusPlain == 'checkin')"
                >
                  Ajouter
                </button>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Site</th>
                        <th>Bloc/Secteur</th>
                        <th>Chambre</th>
                        <th>Ancien Site</th>
                        <th>Ancien Bloc/Secteur</th>
                        <th>Ancienne Chambre</th>
                        <th>Par</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(relocation, index) in singleBookingData.relocations" v-bind:key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ relocation.date }}</td>
                        <td><span class="badge bg-info ms-1">{{ relocation.camp.name }}</span></td>
                        <td><span class="badge bg-primary ms-1">{{ relocation.bloc.name }}</span></td>
                        <td>{{ relocation.room.roomName }}</td>
                        <td><span class="badge bg-info ms-1">{{ relocation.camp_from.name }}</span></td>
                        <td><span class="badge bg-primary ms-1">{{ relocation.bloc_from.name }}</span></td>
                        <td><span class="badge bg-secondary ms-1">{{ relocation.room_from.roomName }}</span></td>
                        <!-- <td>{{ relocation.room.roomName }}</td> -->
                        <td>{{ relocation.by.first_name + ' ' + relocation.by.last_name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Prolongement <span v-if="singleBookingData" class="badge badge-info"> {{singleBookingData.prolongations.length}} </span> </span>
            </template>
            <div class="row" v-if="singleBookingData">
              <div class="col-md-12">
                <button
                  class="btn btn-primary"
                  @click="events.showProlongationModal = true"
                  v-if="$can('create_relocation') && bookingForm.ref && (bookingForm.statusPlain == 'approved' || bookingForm.statusPlain == 'checkin')"
                >
                  Ajouter
                </button>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>ID</th>
                        <th>Date début réservation</th>
                        <th>Date fin réservation</th>
                        <th>Date début Prolongement</th>
                        <th>Date Fin Prolongement</th>
                        <th>Ajoutée le</th>
                        <th>Par</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(relocation, index) in singleBookingData.prolongations" v-bind:key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        
                        <td><span class="badge bg-secondary ms-1">{{ relocation.booking_start_format }}</span></td>
                        <td><span class="badge bg-secondary ms-1">{{ relocation.booking_end_format }}</span></td>
                        <!-- <td>{{ relocation.room.roomName }}</td> -->
                        <td><span class="badge bg-info ms-1">{{ relocation.prolong_start_format}}</span></td>
                        <td><span class="badge bg-primary ms-1">{{ relocation.prolong_end_format}}</span></td>
                        <td>{{ relocation.date }}</td>
                        <td>{{ relocation.by.first_name + ' ' + relocation.by.last_name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </fieldset>
</template>
<script>
import Multiselect from "vue-multiselect";
import rejectionModal from "../../../../common/modals/rejectionModal";
import approvalModal from "../../../../common/modals/approvalModal";
import { formatDate } from "@/helpers/functions";
import { mapGetters } from "vuex";

import {
  fetchRoomsTypesApi,
  fetchEmployeesListApi,
  fetchIdentityDocTypesApi,
  fetchVisitorsListApi,
  fetchBookingBeneficiatyTypesApi,
  fetchLifebasesApi,
  getDropDownElementsByTypeApi,
} from "@/api/common";

import { required, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";


export default {
  components: { Multiselect, rejectionModal, approvalModal },
  import: {
    fetchRoomsTypesApi,
    fetchEmployeesListApi,
    fetchIdentityDocTypesApi,
    fetchVisitorsListApi,
    fetchBookingBeneficiatyTypesApi,
    fetchLifebasesApi,
    getDropDownElementsByTypeApi,
    formatDate,
  },
  props: {
    singleBookingData: {},
    shouldClearAllForms: {
      type: Boolean,
      default: false,
    },
    formDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      title: "Nouvelle réservation",
      roomsTypes: [],
      structures: [],
      disableForm: false,
      disableImportantFields: false,
      identityDocTypes: [],
      beneficiaryTypes: [],
      rooms: [],
      blocs: [],
      companies: [],
      visitors: [],
      employees: [],
      suppliers: [],
      camps: [],
      beneficiaries: [],
      showCheckinButton: false,
      showCheckoutButton: false,
      newBeneficiaryModal: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        identityDocType: "",
        company: "",
        identityDocNo: "",
        idNumber: "", // Matricule d'employé SH
        comments: "",
        type: "",
      },
      checkInModal: {
        roomId: "",
        comments: "",
        bookingId: "",
        bookingUid: "",
      },
      checkOutModal: {
        keyReturned: false,
        room: "",
        comments: "",
        bookingId: "",
        bookingUid: "",
      },
      relocationModal: {
        alternativeRooms: [],
        bookingUid: "",
        bookingId: "",
        oldRoomId: "",
        newRoomId: "",
        occupantId: "",
        comments: "",
        campId: "",
        blocs:[]
      },
      prolongationModal: {
        bookingUid: "",
        bookingId: "",
        from:"",
        to:"",
        comments: "",
      },
      disableBenefType: false,
      events: {
        disabledNewVisitorShortcut: false,
        showVisitorRowForm: false,
        selectVisitorFromDropDown: true,
        showCancelLink: false,
        roomDropDownDisabled: true,
        showNewBeneficiaryModal: false,
        showCheckInModal: false,
        showCheckOutModal: false,
        showRoomsDropdown: false,
        showRelocationModal: false,
        showProlongationModal: false,

        showEmployeeNameField: false,
        showVisitorNameField: false,
      },
      bookingForm: {
        companyId: "",
        visitorId: "",
        roomType: "",
        beneficiaryFullName: "",
        structName: "",
        dateIn: "",
        dateOut: "",
        beneficiaryType: "",
        beneficiaryId: "",
        nights: 0,
        attachment: "",
        structId: "",
        room: "",
        bloc: "",
        occupant: [],
        relocations: [],
        companyName: "",
        supplierId: "",
        comments: "",
        campId: "",
        visitor: {
          fullName: "",
          identityDocType: "",
          identityDocNo: "",
          comments: "",
        },
        keyStatus: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Hébergement",
          href: "/",
        },
        {
          text: "Nouvelle réservation",
          active: true,
        },
      ],
    };
  },

  validations: {
    bookingForm: {
      dateIn: { required },
      dateOut: { required },
      beneficiaryType: { required },
      beneficiaryId: {
        required: requiredIf(function() {
          return this.events.selectVisitorFromDropDown;
        }),
      },
    },

    checkInModal: {
      roomId: { required },
    },
  },
  computed: {
    dateIn() {
      return this.bookingForm.dateIn;
    },

    dateOut() {
      return this.bookingForm.dateOut;
    },

    ...mapGetters({
      authenticated: "apiAuth/authenticated",
      user: "apiAuth/user",
    }),
  },
  watch: {
    formDisabled() {
      if (this.formDisabled) {
        this.disableForm = true;
      } else {
        // this.fetchBaseData();
        // this.fetchAvailableRoomsList();
        this.disableForm = false;
        this.submitted = false;
        this._resetForm();
        this.disableImportantFields = false;
        this.events.showEmployeeNameField = false;
        this.events.showVisitorNameField = false;
      }
      // this.disableForm = this.formDisabled;
    },
    dateIn() {
      this._setNights();
      console.log("this is date in watch");
      this.fetchAvailableRooms();
    },

    dateOut() {
      this._setNights();
      this.fetchAvailableRooms();
    },
    shouldClearAllForms() {
      if (this.shouldClearAllForms) {
        this.submitted = false;
        this._resetForm();
      }
    },

    singleBookingData() {
      if (this.singleBookingData) {
        var booking = this.singleBookingData;
        if (booking) {
          //assign data to form from singleBookingData
          if (booking.checkIn) {
            /// CHECKIN
            this.bookingForm.checkin_at = booking.checkIn.checkin_at;
            this.bookingForm.checkedInBy = booking.checkIn.checkin_by.fullName;
            this.bookingForm.checkInComments = booking.checkIn.comments;
          }
          if (booking.checkOut) {
            /// CHECKOUT
            this.bookingForm.checkout_at = booking.checkOut.checkout_at;
            this.bookingForm.checkedOutBy =
              booking.checkOut.checkout_by.fullName;
            this.bookingForm.checkOutComments = booking.checkOut.comments;
          }

          // data
          if(booking.structure){
            // this.fetchBookingDataStep2(); // fetch hierarchy
            this.bookingForm.structId = booking.structure;
            this.bookingForm.structName = booking.structure.name;
            this.events.showEmployeeNameField = true;
            this.events.showVisitorNameField = false;
          }
          if(booking.visitor) {
            this.events.showEmployeeNameField = false;
            this.events.showVisitorNameField = true;
            this.bookingForm.visitorName = booking.visitor.full_name;
          }
          if(booking.company) {
            this.events.showEmployeeNameField = false;
            this.events.showVisitorNameField = true
            this.bookingForm.companyName = booking.company.company_name;
          }
          if(booking.supplier) {
            this.fetchSuppliersList();
            this.bookingForm.supplierId = booking.supplier;
            this.events.showEmployeeNameField = true;
          }
          this.bookingForm.id = booking.id;
          this.bookingForm.bookingId = booking.uuid;
          this.bookingForm.modelType = booking.modelType;
          this.bookingForm.ref = booking.ref;
          this.bookingForm.dateIn = booking.planned_arrival_date;
          this.bookingForm.dateOut = booking.planned_departure_date;
          this.bookingForm.comments = booking.comments;
          this.bookingForm.nights = booking.nights;
          this.bookingForm.beneficiaryType = booking.occupant_type_obj;
          this.bookingForm.occupant = booking.occupant;
          this.bookingForm.beneficiaryId = booking.occupantResBeta;
          this.bookingForm.beneficiaryFullName = booking.occupantResBeta.fullNameUc;
          this.bookingForm.bloc = booking.bloc;
          this.bookingForm.campId = booking.camp;
          this.bookingForm.statusPlain = booking.statusPlain;
          this.bookingForm.keyStatus = booking.keyStatus;
          if (booking.room.suggested) {
            this.bookingForm.room = booking.room.suggested;
          } else {
            this.bookingForm.room = "";
          }
          if (booking.roomType) {
            this.bookingForm.roomType = booking.roomType;
          } else {
            this.bookingForm.roomType = "";
          }
          this.showCheckinButton = Boolean(booking.checkIn == null);
          if (booking.statusPlain != "draft") {
            this.disableForm = true;
          }
          this.showCheckoutButton =
            booking.checkIn !== null &&
            booking.checkOut === null;
          // console.log("this.showCheckoutButton => " + this.showCheckoutButton);
          if (booking.is_visitor) {
            this.bookingForm.companyName = booking.visitor.companyName;
          }
          // disable important fields
          this.disableImportantFields = booking.statusPlain != "draft";
          // assing data to checkInModal
          this.checkInModal.bookingId = booking.id;
          this.checkInModal.roomId = booking.room.suggested;

          this.prolongationModal.bookingId = this.singleBookingData.id;
          this.prolongationModal.bookingUid = this.singleBookingData.uuid;
          this.prolongationModal.from = this.singleBookingData.planned_departure_date;
        }
      } else {
        this._resetForm();
      }
    },
  },

  created() {
    this.fetchBookingDataStep1();
  },

  mounted() {
    // console.log(this.formatBookingDate('25/09/2021'));
  },

  methods: {
    now() {
      return new Date().toLocaleString("en-GB", { timeZone: "UTC" });
    },
    onStructChange(){
      this.fetchBookingDataStep3();
    },
    onCompanyChange(){
      this.fetchCompanyVisitors();
    },
    cancelForm() {
      this.formDisabled = true;
      this.submitted = false;
      this._resetForm();
      this.$emit("freeAddBtn", true);
    },
    _refreshSelectedBookingData() {
      if (this.singleBookingData) {
        var bookingUid = this.singleBookingData.uuid;
        var loader = this.$loading.show();
        this.$http
          .post("/ops/booking/getSingleBookingData", {
            bookingId: bookingUid,
          })
          .then((res) => {
            loader.hide();
            var booking = res.data.booking;
            if (booking.checkIn) {
              /// CHECKIN
              this.bookingForm.checkin_at = booking.checkIn.checkin_at;
              this.bookingForm.checkedInBy =
                booking.checkIn.checkin_by.fullName;
              this.bookingForm.checkInComments = booking.checkIn.comments;
            }
            if (booking.checkOut) {
              /// CHECKOUT
              this.bookingForm.checkout_at = booking.checkOut.checkout_at;
              this.bookingForm.checkedOutBy =
                booking.checkOut.checkout_by.fullName;
              this.bookingForm.checkOutComments = booking.checkOut.comments;
            }

            // data
            this.bookingForm.id = booking.id;
            this.bookingForm.bookingId = booking.uuid;
            this.bookingForm.supplierId = booking.supplier;
            this.bookingForm.modelType = booking.modelType;
            this.bookingForm.ref = booking.ref;
            this.bookingForm.dateIn = booking.planned_arrival_date;
            this.bookingForm.dateOut = booking.planned_departure_date;
            this.bookingForm.comments = booking.comments;
            this.bookingForm.nights = booking.nights;
            this.bookingForm.beneficiaryType = booking.occupant_type_obj;
            this.bookingForm.occupant = booking.occupant;
            this.bookingForm.beneficiaryId = booking.occupantResBeta;
            this.bookingForm.campId = booking.camp;
            this.bookingForm.statusPlain = booking.statusPlain;
            if (booking.room.suggested) {
              this.bookingForm.room = booking.room.suggested;
            } else {
              this.bookingForm.room = "";
            }
            if (booking.roomType) {
              this.bookingForm.roomType = booking.roomType;
            } else {
              this.bookingForm.roomType = "";
            }
            this.showCheckinButton = Boolean(booking.checkIn == null);
            if (booking.statusPlain != "draft") {
              this.disableForm = true;
            }
            this.showCheckoutButton =
              Boolean(booking.checkIn != null) &&
              Boolean(booking.checkOut == null);

            if (booking.is_visitor) {
              this.bookingForm.companyName = booking.visitor.companyName;
            }
            this.bookingForm.relocations = booking.relocations;
            // assing data to checkInModal
            this.checkInModal.bookingId = booking.id;
            this.checkInModal.roomId = booking.room.suggested;
          })
          .catch(() => {
            loader.hide();
          })
          .finally(() => {
            loader.hide();
          });
      }
    },

    fetchBookingDataStep1(){
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/fetchBookingDataStep1")
        .then((res) => {
          loader.hide();
          this.camps = res.data.camps;
          this.beneficiaryTypes = res.data.beneficiaryTypes;
          this.roomsTypes = res.data.roomsClassifications;
          // this.structures = res.data.structures;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
    },

    fetchBookingDataStep2(){
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/fetchBookingDataStep2")
        .then((res) => {
          loader.hide();
          var directions = res.data.directions;
          var departments = res.data.departments;
          var services = res.data.services;
          this.structures = directions.concat(departments).concat(services);
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
    },

    fetchCompanyVisitors(){
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/fetchCompanyVisitors", {
          companyId: this.bookingForm.companyId,
        })
        .then((res) => {
          loader.hide();
          this.beneficiaries = res.data.visitors;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
    },

    fetchBookingDataStep3(){
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/fetchBookingDataStep3", {
          structId: this.bookingForm.structId.code,
        })
        .then((res) => {
          loader.hide();
          this.beneficiaries = res.data.employees;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
    },

    fetchBaseData() {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/fetchBaseData")
        .then((res) => {
          loader.hide();
          this.camps = res.data.camps;
          this.beneficiaryTypes = res.data.beneficiaryTypes;
          this.roomsTypes = res.data.roomsClassifications;
          // this.structures = res.data.structures;
          // this.suppliers = res.data.suppliers;
          // this.visitors = res.data.visitors;
          // this.employees = res.data.employees;
          // this.identityDocTypes = res.data.identityDocTypes;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
    },
    showApprobationModal() {},
    showRejectionModal() {},

    _reloadBookingData() {
      console.log("#####");
    },

    handleBookingCancelClick() {
      Swal.fire({
        title:
          "Êtes-vous sûr d'annuler la réservation N°: " +
          this.singleBookingData.ref +
          "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "OUI !",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/ops/booking/close", {
              bookingUid: this.singleBookingData.uuid,
            })
            .then((res) => {
              var status = res.data.original.status;
              loader.hide();
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  // this._refreshButtonsCount();
                  this.$emit("refreshButtonsCount", true);
                  this.shouldRefreshBookingsTable = true;
                  this.events.shouldClearAllForms = true;
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    onSupplierChange(){
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/fetchSupplierEmployees", {
          supplierId: this.bookingForm.supplierId.uuid,
        })
        .then((res) => {
          loader.hide();
          this.beneficiaries = res.data.employees;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
    },  

    saveRelocation() {
      this.submitted = true;
      // this.$v.relocationModal.$touch();
      // if (!this.$v.relocationModal.$invalid) {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/relocate", this.relocationModal)
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$emit('refresh',true);
              this.$toast.success(res.data.original.msg);
              this.events.showRelocationModal = false;
              this._refreshSelectedBookingData();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          loader.hide();
        });
      // }
    },

    saveProlongation() {
      // this.submitted = true;
      // this.$v.relocationModal.$touch();
      // if (!this.$v.relocationModal.$invalid) {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/extend", this.prolongationModal)
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$emit('refresh',true);
              this.$toast.success(res.data.original.msg);
              this.events.showProlongationModal = false;
              this._refreshSelectedBookingData();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          loader.hide();
        });
      // }
    },

    performCheckin() {
      // add swal confirm
      // room required
      this.submitted = true;
      this.$v.checkInModal.$touch();
      if (!this.$v.checkInModal.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/ops/booking/checkin", this.checkInModal)
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.events.showCheckInModal = false;
                this.$toast.success(res.data.original.msg);
                this.$emit("checkInPerformed", true);
                this._refreshSelectedBookingData();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {
            loader.hide();
          });
      }
    },

    performCheckout() {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/checkout", this.checkOutModal)
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.events.showCheckOutModal = false;
              this.$emit("checkOutPerformed", true);
              this._refreshSelectedBookingData();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          loader.hide();
        });
    },
    showCheckinModal() {
      this.checkInModal.bookingId = this.singleBookingData.id;
      this.checkInModal.bookingUid = this.singleBookingData.uuid;
      this.events.showCheckInModal = true;
    },
    showCheckOutModal() {
      this.checkOutModal.bookingId = this.singleBookingData.id;
      this.checkOutModal.bookingUid = this.singleBookingData.uuid;
      this.events.showCheckOutModal = true;
    },
    saveNewBenefiaryModal() {
      this.$loading.show();
    },

    showRelocationModalClick() {
      this._fetchCampsList();
      this.relocationModal.bookingId = this.singleBookingData.id;
      this.relocationModal.bookingUid = this.singleBookingData.uuid;
      this.events.showRelocationModal = true;
    },

    onRelocationCampChange() {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/getAlternativeAvailableRooms", {
          to: this.bookingForm.dateOut,
          campId: this.relocationModal.campId.id,
          blocId: this.relocationModal.blocId.id,
          roomTypeId: this.bookingForm.roomType.id,
        })
        .then((res) => {
          loader.hide();
          this.relocationModal.alternativeRooms =
            res.data.availableRooms.allRooms;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
    },

    formatBookingDate(date) {
      return formatDate(date, "MM/DD/YYYY");
    },

    _setNights() {
      var startDate = this.bookingForm.dateIn;
      var endDate = this.bookingForm.dateOut;
      var nights = this.diffInDays(startDate, endDate);
      if (!isNaN(nights)) {
        if (nights >= 1) {
          this.bookingForm.nights = nights;
        } else {
          this.bookingForm.nights = 0;
          this.$toast.error(
            "La date départ doit être supérieur a la date d'arrivée"
          );
          // this.bookingForm.dateIn = "";
          this.bookingForm.dateOut = "";
        }
      } else {
        this.bookingForm.nights = 0;
      }
    },
    _fetchCampsList() {
      var loader = this.$loading.show();
      fetchLifebasesApi()
        .then((res) => {
          loader.hide();
          this.camps = res.data.original.list;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
      // bases
    },
    diffInDays(startDate, endDate) {
      const diffInMs = new Date(endDate) - new Date(startDate);
      return diffInMs / (1000 * 60 * 60 * 24);
    },
    handleQuickOccupantAdd() {
      this.events.showNewBeneficiaryModal = true;
    },
    handleQuickOccupantCancel() {
      this.events.disabledNewVisitorShortcut = false;
      this.events.showCancelLink = false;
      this.events.selectVisitorFromDropDown = true;
      this.bookingForm.visitor.fullName = "";
      this.bookingForm.visitor.identityDocType = "";
      this.bookingForm.visitor.identityDocNo = "";
    },
    fetchAvailableRooms() {
      if (this.bookingForm.roomType) {
        var type = this.bookingForm.roomType.id;
      }
      var dateIn = this.bookingForm.dateIn;
      var dateOut = this.bookingForm.dateOut;
      if (type && dateIn && dateOut) {
        this.fetchAvailableRoomsList();
      }
    },
    fetchAvailableRoomsList() {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/getRoomsAvailabilityBeta", {
          from: this.bookingForm.dateIn,
          to: this.bookingForm.dateOut,
          campId: this.bookingForm.campId.id,
          blocId: this.bookingForm.bloc ? this.bookingForm.bloc.id : null,
          roomTypeId: this.bookingForm.roomType.id,
        })
        .then((res) => {
          loader.hide();
          this.rooms = res.data.availableRooms.allRooms;
          this.events.roomDropDownDisabled = false;
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    onCampChange() {
      // get rooms of selected camp
      this.blocs = this.bookingForm.campId.buildings;
      // this.fetchAvailableRooms();
    },

    onBeneficiaryTypeChange() {

      if(this.bookingForm.beneficiaryType.id == 'sh_employee') {
        // fetch structs
        this.fetchBookingDataStep2();
      }

      if(this.bookingForm.beneficiaryType.id == 'visitor') {
        // fetch structs
        this.fetchBookingDataStepVisitorCompanies();
      }

      if(this.bookingForm.beneficiaryType.id == 'supplier_employee') {
        // fetch structs
        this.fetchSuppliersList();
      }

      // this.fetchBeneficiatyList();
    },

    fetchBookingDataStepVisitorCompanies(){
        var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/getVisitorsCompanies")
        .then((res) => {
          loader.hide();
          this.companies = res.data.companies;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
    },

    _fetchSupplierStaffList() {},
    fetchBeneficiatyList() {
      // employees, visitors or supplier staff
      var type = this.bookingForm.beneficiaryType.id;
      switch (type) {
        case "sh_employee":
          this.beneficiaries = this.employees;
          break;

        case "visitor":
          this.beneficiaries = this.visitors;
          break;

        case "supplier_employee":
          this._fetchSupplierStaffList();
          break;
      }
    },

    fetchSuppliersList() {
      this.$http
        .post("/contracts/suppliers/list")
        .then((res) => {
          this.suppliers = res.data.suppliers
        })
        .catch(() => {})
        .finally(() => {});
    },
    _resetForm() {
      this.submitted = false;
      this.bookingForm.companyId = "";
      this.bookingForm.visitorId = "";
      this.bookingForm.beneficiaryFullName = "";
      this.bookingForm.structName = "";
      this.bookingForm.bloc = "";
      this.bookingForm.roomType = "";
      this.bookingForm.dateIn = "";
      this.bookingForm.dateOut = "";
      this.bookingForm.campId = "";
      this.bookingForm.beneficiaryType = "";
      this.bookingForm.beneficiaryId = "";
      this.bookingForm.nights = "";
      this.bookingForm.room = "";
      this.bookingForm.comments = "";
      this.bookingForm.ref = null;
      this.bookingForm.statusPlain = "";
      this.bookingForm.checkin_at = "";
      this.bookingForm.checkedInBy = "";
      this.bookingForm.checkInComments = "";
      this.bookingForm.checkout_at = "";
      this.bookingForm.checkedOutBy = "";
      this.bookingForm.checkOutComments = "";
      this.bookingForm.id = null;
      this.bookingForm.bookingId = null;
      this.bookingForm.modelType = null;
    },
    formSubmit() {
      this.submitted = true;
      this.$v.bookingForm.$touch();
      if (!this.$v.bookingForm.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/ops/booking/store", this.bookingForm)
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this._resetForm();
                this.$emit("bookingStored", status);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
  },
};
</script>
