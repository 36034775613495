var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        scopedSlots: _vm._u([
          {
            key: "datePicker",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "ml-2",
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.showRoomsAvailabilityModalClick }
                  },
                  [_vm._v(" Disponibilité ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "buttons",
            fn: function() {
              return [
                _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "div",
                        { staticClass: "button-items hosting-actions" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function($event) {
                                  return _vm.filterBookings("requests")
                                }
                              }
                            },
                            [
                              _vm._v(" Demandes "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  class:
                                    _vm.counts.requests > 0
                                      ? "badge-warning"
                                      : "badge-success"
                                },
                                [_vm._v(_vm._s(_vm.counts.requests))]
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function($event) {
                                  return _vm.filterBookings("approved")
                                }
                              }
                            },
                            [
                              _vm._v(" Approuvé "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  class:
                                    _vm.counts.approved > 0
                                      ? "badge-warning"
                                      : "badge-success"
                                },
                                [_vm._v(_vm._s(_vm.counts.approved))]
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function($event) {
                                  return _vm.filterBookings("rejected")
                                }
                              }
                            },
                            [
                              _vm._v(" Rejeté "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  class:
                                    _vm.counts.rejected > 0
                                      ? "badge-warning"
                                      : "badge-success"
                                },
                                [_vm._v(_vm._s(_vm.counts.rejected))]
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function($event) {
                                  return _vm.filterBookings("closed")
                                }
                              }
                            },
                            [
                              _vm._v(" Annulé "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  class:
                                    _vm.counts.closed > 0
                                      ? "badge-warning"
                                      : "badge-success"
                                },
                                [_vm._v(_vm._s(_vm.counts.closed))]
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function($event) {
                                  return _vm.filterBookings("checkin_today")
                                }
                              }
                            },
                            [
                              _vm._v(" Check IN "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  class:
                                    _vm.counts.checkIn > 0
                                      ? "badge-warning"
                                      : "badge-success"
                                },
                                [_vm._v(_vm._s(_vm.counts.checkIn))]
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function($event) {
                                  return _vm.filterBookings("checkout_today")
                                }
                              }
                            },
                            [
                              _vm._v(" Check OUT "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  class:
                                    _vm.counts.checkOut > 0
                                      ? "badge-warning"
                                      : "badge-success"
                                },
                                [_vm._v(_vm._s(_vm.counts.checkOut))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("SideButtons", {
        attrs: { reset: _vm.resetAddBtn, save: _vm.items },
        on: {
          info: _vm.items,
          add: _vm.addBookingClickEvent,
          save: _vm.items,
          edit: _vm.editBookingClickEvent,
          confirm: _vm.items,
          validate: _vm.items,
          delete: _vm.deleteBookingClickEvent
        }
      }),
      _c("gui-availability-modal", {
        attrs: {
          show: _vm.showRoomsAvailabilityModal,
          from: _vm.prefilledRoomAvailability.from,
          to: _vm.prefilledRoomAvailability.to,
          camps: _vm.bases
        },
        on: {
          closeAvailabilityModal: function($event) {
            _vm.showRoomsAvailabilityModal = $event
          }
        }
      }),
      _c("bookings-list", {
        attrs: {
          refreshBookings: _vm.shouldRefreshBookingsTable,
          searchResult: _vm.bookings_SearchResult
        },
        on: {
          showRoomsAvailabilityForRequest:
            _vm.handleRoomsAvailabilityForSingleRequestEvent,
          showSingleBookingData: _vm.handleShowSingleBookingDataEvent,
          refreshOK: function($event) {
            _vm.shouldRefreshBookingsTable = $event
          }
        }
      }),
      _c("booking-form-beta", {
        attrs: {
          formDisabled: _vm.events.disableForm,
          singleBookingData: _vm.singleBookingData,
          shouldClearAllForms: _vm.events.shouldClearAllForms
        },
        on: {
          checkOutPerformed: _vm.handleCheckOutPerformedEvent,
          checkInPerformed: _vm.handleCheckInPerformedEvent,
          bookingStored: _vm.handleBookingStoredEvent,
          freeAddBtn: _vm.handleFreeAddBtnEvent,
          refreshButtonsCount: _vm.handleRefreshButtonsCountEvent,
          refresh: function($event) {
            _vm.shouldRefreshBookingsTable = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }