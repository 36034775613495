<template>
  <div class="row">
    <div class="col-lg-12">
      <search @performBookingSearch="handlePerformBookingSearchEvent" @resetSearchForm="handleResetSearchFormEvent"/>

      <div class="accordion mb-2" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              @click="active = !active"
              style="background:#e5e9f0; color:black !important; border:none !important;"
            >
              <div class="row">
                <div class="col text-left mt-1">
                  <i class="mdi mdi-format-list-bulleted"></i> Liste des
                  Réservations
                </div>
                <div class="col text-right">
                  <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i>
                  <i v-else class="mdi mdi-chevron-down font-size-18"></i>
                </div>
              </div>
            </b-button>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            :visible="active"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body class="shadow-lg">
              <download-excel 
                  :fields="excel_header"
                  style="cursor:pointer" 
                  class="float-right" 
                  worksheet="Prestation Restauration"
                  name="PrestationRestauration.xls"
                  :data="bookings.data">
                    <img width="40" :src="require('@/assets/images/base/excel.png')" />
                  </download-excel>
                <dataset v-slot="{ ds }" :ds-data="bookings.data">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table
                          class="table table-hover d-md-table table-striped"
                        >
                          <thead>
                            <tr>
                              <th v-for="th in cols" :key="th.name">
                                {{ th.name }}
                              </th>
                              <th v-if="searchData.status == 'checkout'">
                                  Clé Remise
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="onSingleBookingSelect(row, rowIndex)" :class="{ 'bg-soft-info': selectedIndex == rowIndex }" style="cursor:pointer">
                                <td>
                                  <b v-if="row.occupantResBeta">{{ row.occupantResBeta.empNum }}</b>
                                  <b v-else>{{ "N/A" }}</b>
                                </td>
                                <td><span v-if="row.occupantResBeta">{{ row.occupantResBeta.fullNameUc }}</span></td>

                                <td><span style="font-size:14px;" class="badge bg-info ms-1">{{ row.createdBy }}</span></td>
                                <td><span style="font-size:14px;" class="badge bg-primary">{{ row.camp.name }}</span></td>
                                <td><span style="font-size:18px;" v-html="row.occupant_type_html" class="ms-1"></span></td>
                                <td>{{ row.planned_arrival_date }}</td>
                                <td>{{ row.planned_departure_date }}</td>
                                <td>
                                  <template v-if="row.checkIn"><span style="font-size:18px;" class="badge bg-info ms-1">{{ row.assignedRoom.roomName }}</span></template>
                                  <template v-else><span v-if="row.suggestedRoom" style="font-size:18px;" class="badge bg-info ms-1">{{ row.suggestedRoom.roomName }}</span></template>
                                </td>
                                <td><span style="font-size:14px;" v-html="row.status"></span></td>
                                <td class="text-center" v-if="searchData.status == 'checkout'">
                                  <span v-if="row.key_returned == 1"><i class="far fa-check-circle text-success"></i></span>
                                  <span v-else><i class="far fa-times-circle text-danger"></i></span>
                                </td>
                                
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                  >
                    <!-- <dataset-info class="mb-2 mb-md-0" /> -->
                    <dataset-show class="mb-2" v-model="defaultShow" @changed="onShowChange" :ds-show-entries="defaultShow"/>
                    <pagination
                        :data="bookings"
                        :limit="defaultShow"
                        class="float-right"
                        @pagination-change-page="refreshBookingsTable"
                      />
                  </div>
                </dataset>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
import search from "../../components/search";
export default {
  props: {
    refreshBookings: {
      type: Boolean,
      default: false,
    },
    searchResult: {},
  },
  components: {Dataset, DatasetItem, DatasetShow, search},
  data() {
    return {
      excel_header: {
        "N° Emp": "occupantResBeta.empNum",
        "Occupant": "occupantResBeta.fullNameUc",
        "Site": "camp.name",
        "Type": "occupant_type_html",
        "Arrivé": "planned_arrival_date",
        "Départ": "planned_departure_date",
        "Chambre": "suggestedRoom.roomName",
      },

      active: true,
      bookings: [],
      defaultShow : 5,
      selectedIndex: -1,
      searchData:{
        status:""
      },
      cols: [
        {
          name: "N° Emp",
          field: "",
          sort: "",
        },
        {
          name: "Occupant",
          field: "",
          sort: "",
        },
        {
          name: "Crée Par",
          field: "",
          sort: "",
        },
        {
          name: "Site",
          field: "",
          sort: "",
        },
        {
          name: "Type",
          field: "",
          sort: "",
        },
        
        {
          name: "Arrivé",
          field: "",
          sort: "",
        },
        {
          name: "Départ",
          field: "",
          sort: "",
        },
        {
          name: "Chambre",
          field: "",
          sort: "",
        },
        // {
        //   name: "Nuités",
        //   field: "",
        //   sort: "",
        // },
        {
          name: "Status",
          field: "",
          sort: "",
        },
      ],
    };
  },

  watch: {
    refreshBookings(){
      if(this.refreshBookings){
          this.refreshBookingsTable();
          this.$emit('refreshOK',false)
      }
    },

    searchResult:{
      handler(val){
        // console.log(val);
        if(val.data.length > 0){
          this.bookings = val;
        }
      }
      
    }
  },

  mounted() {
    this.refreshBookingsTable();
  },

  methods: {

    handlePerformBookingSearchEvent(event, page = 1){
      this.searchData = event;
       var loader = this.$loading.show();
      var show = this.defaultShow;
      
      this.$http
        .post("/ops/booking/simpleSearch?show=" + show +"&page="+page, {
          searchForm: event,
        })
        .then((res) => {
          loader.hide();
          this.bookings = res.data
        })
        .catch((error) => {
          loader.hide();
          this.$toast.warning(error.message);
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    handleResetSearchFormEvent(event){
      if(event) {
        this.bookings = [];
        this.refreshBookingsTable(1);
      }
    },

    handleTableRowRoomsAvailabilityEvent(event) {
      this.$emit("showRoomsAvailabilityForRequest", event);
    },

    handleShowSingleBookingDataEvent(booking) {
      this.$emit("showSingleBookingData", booking);
    },
    showAvailabilityModal(request){
      this.$emit('showRoomsAvailabilityForRequest', request);
    },
   
    deleteBooking(contRef, contUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la réservation N°: " + contRef + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/booking/delete/" + contUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  _this.refreshBookingsTable();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    refreshBookingsTable(page = 1) {
      var loader = this.$loading.show();
      var show = this.defaultShow;
      this.$http
        .post("/ops/booking/paginate?show=" + show +"&page="+page)
        .then((res) => {
          loader.hide();
          this.bookings = res.data
        })
        .catch((error) => {
          loader.hide();
          this.$toast.warning(error.message);
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },
    
    onShowChange(){

    },

    onSingleBookingSelect(booking, rowIndex){
      this.selectedIndex = rowIndex;
      this.$emit('showSingleBookingData', booking);
    }
  },
};
</script>
